// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:less
@import "../../bower_components/bootstrap/less/bootstrap.less";
// endbower

@import "common/_variables";
@import "common/_global";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";

@font-face {
  font-family: 'Justus-Roman';
  src: url('../fonts/justus-roman.eot');
  src: url('../fonts/justus-roman.eot?#iefix') format('embedded-opentype'),
       url('../fonts/justus-roman.woff') format('woff'),
       url('../fonts/justus-roman.ttf') format('truetype'),
       url('../fonts/justus-roman.svg#Justus-Roman') format('svg');
}

body {
  background-color: #f2eade;
  font-family: 'Justus-Roman';
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}

a:focus, a:hover {
  text-decoration: none;
}

a:hover {
  color: #5e5d5a;
}

a.back-to-top {
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  display: inline-block;
  transition: all 1s ease;
}

a.back-to-top:hover {
  -moz-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

a.close-button,
a.close-button > span {
  border-radius: 50%;
  display: inline-block;
}

a.close-button {
  background: #c01f36;
  width: 21px;
  height: 21px;
  position: relative;
  vertical-align: top;
}

a.close-button > span {
  background: #f2eade;
  display: block;
  height: 15px;
  left: 3px;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 15px;
}

a.close-button .close-x {
  font-size: 17px;
  font-weight: 100;
  position: relative;
  top: -6px;
}

a.close-button:hover {
  background: #c06c78;
}

a.close-button:hover > span {
  color: #c06c78;
}

.cap-logo {
  float: right;
}

.cap-logo img {
  position: relative;
  top: 5px;
}

.caret-link {
  -moz-transform: scale(1.5) rotate(-90deg);
  -moz-transition: all 0.25s ease;
  -ms-transform: scale(1.5) rotate(-90deg);
  -ms-transition: all 0.25s ease;
  -o-transform: scale(1.5) rotate(-90deg);
  -o-transition: all 0.25s ease;
  -webkit-transform: scale(1.5) rotate(-90deg);
  -webkit-transition: all 0.25s ease;
  transform: scale(1.5) rotate(-90deg);
  transition: all 0.25s ease;
}

.caret-link.active {
  -moz-transform: scale(1.5) rotate(0deg);
  -webkit-transform: scale(1.5) rotate(0deg);
  -o-transform: scale(1.5) rotate(0deg);
  -ms-transform: scale(1.5) rotate(0deg);
  transform: scale(1.5) rotate(0deg);
}

.empty-text {
  float: left;
  padding: 0px 20px;
  position: relative;
  width: 75%;
}

.filter li.active {
  color: #c01f36;
  font-weight: 900;
  font-size: 16px;
}

.filter li a {
  color: #c01f36;
}

.filter li a:hover {
  color: #c06c78;
}

.filter ul {
  -webkit-transition: all 0.75s ease;
  -moz-transition: all 0.75s ease;
  -ms-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
  opacity: 0;
  padding-left: 15px;
  height: 0;
  overflow: hidden;
}

.filter ul.show {
  height: auto;
  opacity: 1;
}

.flush {
  margin: 0;
}

.fullpage-wrapper {
  overflow-x: hidden;
}

.footer {
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  bottom: 10px;
  font-size: 20px;
  opacity: 0;
  position: fixed;
  right: 40px;
  transition: all 1s ease;
  visibility: hidden;
}

footer.visible {
  opacity: 1;
  visibility: visible;
}

.guide-title {
  min-height: 180px;
  padding-bottom: 10px;
}

.guide-titles > .row {
  margin-top: 50px;
}

.guide-titles > div:first-child {
  margin-top: 0 !important;
}

.guide-titles-window {
  padding-left: 30px;
}

.guide-title-details {
  font-size: 16px;
}

.guide-title-details div span {
  font-weight: bolder;
}

.guide-title-details h2 {
  font-size: 20px;
  padding: 0 20px;
}

.guide-title-details,
.guide-title-image {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.guide-title-image a {
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  display: block;
  transition: all 0.25s ease;
}

.guide-title-image a:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.guide-title-sidebar {
  border-left: 1px solid #eee;
}

.guide-title-sidebar h3,
.sidebar-callout h3 {
  padding-bottom: 10px;
}

.guide-title-sidebar ul {
  list-style: none;
}

.header-img {
  display: inline-block;
  width: 100%;
}

.input-sm-label {
  line-height: 30px;
  text-align: center;
}

.lead-text a {
  color: #c01f36;
}

.lead-text a:hover {
  color: #c06c78;
}

.mobile-only > .lead-text {
  display: none;
}

.navbar {
  -moz-box-shadow: 0 0 3px 3px #221E1F;
  -webkit-box-shadow: 0 0 3px 3px #221E1F;
  background-color: #221E1F;
  border: 0;
  box-shadow: 0 0 3px 3px #221E1F;
  margin-bottom: 0;
  min-height: 60px;
  position: fixed;
  width: 100%;
}

.navbar-brand img {
  position: relative;
  top: -5px;
}

.navbar-header {
  width: 100%;
}

.page-header {
  border-bottom: none;
  margin-top: 0px;
}

.parallax {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
  perspective-origin-x: 100%;
  transform-style: preserve-3d;
}

.parallax-layer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin-x: 100%;
}

.parallax-layer-back {
  -moz-transform: scale(1.2) translateZ(-0.2px);
  -ms-transform: scale(1.2) translateZ(-0.2px);
  -o-transform: scale(1.2) translateZ(-0.2px);
  -webkit-transform: scale(1.2) translateZ(-0.2px);
  top: 80px;
  transform: scale(1.2) translateZ(-0.2px);
}

.parallax-layer-base {
  -moz-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
}

.parameters {
  padding-left: 24px;
}

.parameters .active-filters a,
.parameters .active-search a {
  color: #c01f36;
}

.parameters .active-filters a:hover,
.parameters .active-search a:hover {
  color: #c06c78;
}

.parameters p {
  font-size: 16px;
  margin-bottom: 5px;
}

.parameters span {
  font-weight: bolder;
}

.search-form {
  float: left;
  padding-top: 12px;
  width: 20%;
}

.sort-form select {
  display: inline-block;
  width: 70%;
}

.sidebar-callout {
  background: #f2eade;
  padding: 20px 0;
  width: 260px;
}

.sidebar-callout img {
  margin: 10px 0 20px;
  width: 195px;
}

.wp-post-image {
  display: inline-block;
}

@media (max-width: 1199px) {
  .guide-title {
    margin-top: 60px;
  }

  .guide-title.odd {
    clear: both;
  }

  .guide-titles > .row {
    margin-top: 0;
  }

  .guide-titles > .row:after {
    clear: none;
  }
}

@media (max-width: 767px) {
  body {
    overflow-y: auto;
  }

  label[for="sortby"] {
    float: left;
  }

  select.input-sm {
    width: 100%;
  }

  .cap-logo {
    display: none;
  }

  .footer {
    display: none;
  }

  .empty-text {
    float: none;
    margin: 0 auto;
    padding: 0 15px;
    width: 75%;
  }

  .guide-titles.loading:after {
    color: #c01f36;
    content: "Loading More...";
    display: block;
    font-size: 24px;
    text-align: center;
  }

  .guide-titles.loaded:after {
    background-color: #000;
    color: #fff;
    content: "© 2015 Classical Academic Press";
    display: block;
    font-size: 16px;
    padding: 10px 20px;
  }

  .filters,
  .guide-title-sidebar > h3 {
    text-align: center;
  }

  .guide-title-sidebar .sidebar-callout {
    display: none;
  }

  .fullpage-wrapper > .wrap {
    padding-top: 75px;
  }

  .guide-titles-window {
    padding: 0;
  }

  .input-sm-label {
    padding-left: 0;
    text-align: left;
  }

  .lead-text {
    display: none;
  }

  .mobile-only > .lead-text {
    display: block;
  }

  .navbar {
    height: 60px;
    position: initial;
  }

  .navbar-header {
    padding-right: 15px;
  }

  .parameters {
    text-align: center;
  }

  .parallax {
    height: initial;
    overflow-y: hidden;
    -webkit-perspective: initial;
    perspective-origin-x: initial;
    -webkit-transform-style: initial;
    transform-style: initial;
  }

  .parallax-layer {
    bottom: initial;
    left: initial;
    position: static;
    right: initial;
    top: initial;
    transform-origin-x: initial;
  }

  .parallax-layer-back {
    -moz-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
    top: initial;
    -webkit-transform: initial;
    transform: initial;
  }

  .parallax-layer-base {
    -ms-transform: initial;
    -o-transform: initial;
    -webkit-transform: initial;
    transform: initial;
  }

  .search-form {
    float: none;
    position: relative;
    top: 20px;
    width: 100%;
    margin-left: 20px;
  }

  .sidebar-callout a {
    width: 75%;
  }

  .sorting {
    margin: 0 auto;
    width: 75%;
  }
}

@media (max-width: 3000px) {
  .parallax-layer-base {
    top: 825px;
  }
}

@media (max-width: 2900px) {
  .parallax-layer-base {
    top: 800px;
  }
}

@media (max-width: 2800px) {
  .parallax-layer-base {
    top: 775px;
  }
}

@media (max-width: 2700px) {
  .parallax-layer-base {
    top: 750px;
  }
}

@media (max-width: 2600px) {
  .parallax-layer-base {
    top: 725px;
  }
}

@media (max-width: 2500px) {
  .parallax-layer-base {
    top: 700px;
  }
}

@media (max-width: 2400px) {
  .parallax-layer-base {
    top: 675px;
  }
}

@media (max-width: 2400px) {
  .parallax-layer-base {
    top: 650px;
  }
}

@media (max-width: 2300px) {
  .parallax-layer-base {
    top: 625px;
  }
}

@media (max-width: 2200px) {
  .parallax-layer-base {
    top: 600px;
  }
}

@media (max-width: 2100px) {
  .parallax-layer-base {
    top: 575px;
  }
}

@media (max-width: 2000px) {
  .parallax-layer-base {
    top: 575px;
  }
}

@media (max-width: 1900px) {
  .parallax-layer-base {
    top: 550px;
  }
}

@media (max-width: 1800px) {
  .parallax-layer-base {
    top: 525px;
  }
}

@media (max-width: 1700px) {
  .parallax-layer-base {
    top: 500px;
  }
}

@media (max-width: 1600px) {
  .parallax-layer-base {
    top: 475px;
  }
}

@media (max-width: 1500px) {
  .parallax-layer-base {
    top: 450px;
  }
}

@media (max-width: 1400px) {
  .parallax-layer-base {
    top: 425px;
  }
}

@media (max-width: 1300px) {
  .parallax-layer-base {
    top: 400px;
  }
}

@media (max-width: 1200px) {
  .parallax-layer-base {
    top: 375px;
  }
}

@media (max-width: 1100px) {
  .parallax-layer-base {
    top: 350px;
  }
}

@media (max-width: 1000px) {
  .parallax-layer-base {
    top: 325px;
  }
}

@media (max-width: 900px) {
  .parallax-layer-base {
    top: 300px;
  }
}

@media (max-width: 800px) {
  .parallax-layer-base {
    top: 275px;
  }
}

@media (max-width: 700px) {
  .parallax-layer-base {
    top: 250px;
  }
}

